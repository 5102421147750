import React, { useContext, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MainContext } from "context/context"
import { isActiveLink } from 'utils'
import { navigate } from 'gatsby'
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"
import Link from 'components/link'
import Select from 'react-select'
import 'vendor/hamburgers.css'

export default ({
	location
}) => {
	const { siteFields } = useStaticQuery(graphql`
        query {
            siteFields {
				pages {
					nodes {
						slug
						title
					}
				}
                menu (
					id: "TWVudTo0"
				) {
					menuItems {
						nodes {
							label
							url
						}
					}
				}
            }
        }
	`)

	const { nodes: pages } = siteFields.pages

	const [isBlog, setIsBlog] = useState(false)

	const _pages = pages.filter(page => page.slug !== 'win')
	const __pages = _pages.filter(page => page.slug !== 'blog')


	const selectOptions = __pages.reverse().map(page => {
		return {
			value: page.slug,
			label: page.title
		}
	})

	const {
		options,
		isHeaderInverted,
		hasScrolled,
		checkInvertedHeaderOffsets,
	} = useContext(MainContext)

	const {
		logos
	} = options

	useEffect(() => {
		{
			( location.pathname === '/blog' || location.pathname === '/blog/' ) ?
				setIsBlog(true)
			:
				setIsBlog(false)
		}

		setTimeout(() => {
			checkInvertedHeaderOffsets()
		}, 1000)

		if (typeof document !== 'undefined' && location && location.search && location.search.indexOf('scrollTo') !== -1) {
			setTimeout(() => {
				const idToScrollTo = location.search.split('scrollTo=')[1]

				const element = document.getElementById(idToScrollTo)

				if(element){
					scroll.scrollTo(element.offsetTop - 120)
				}else{
					console.log('no '+element)
				}
			}, 500)
		}
	}, [location])

	const [selectedLanguage, setSelectedLanguage] = useState((() => {
		const pathSlug = location.pathname.replace('/', '')

		let selectedIndex = 0

		if (pathSlug) {
			selectedIndex = selectOptions.findIndex(option => {
				return option.value === pathSlug
			})
		}

		return selectOptions[selectedIndex]
	})())

	const [selectedChildItems, setSelectedChildItems] = useState([])
	const [hoveredMenuItemIndex, setHoveredMenuItemIndex] = useState(-1)

	let headerClassName = 'header'

	if (isHeaderInverted) {
		headerClassName += ' inverted'
	}

	if (isBlog && isHeaderInverted) {
		headerClassName += ' blog-margin'
	}

	if (hasScrolled) {
		headerClassName += ' has-scrolled'
	}

	const handleHover = (index, items = []) => {
		if (items.length) {
			setSelectedChildItems(items)
			setHoveredMenuItemIndex(index)
		} else {
			setHoveredMenuItemIndex(-1)
		}
	}

	let headerContainerName = 'header-container'

	const handleSelectChange = value => {
		setSelectedLanguage(value)
		navigate(value.value == 'en' ? '/' : value.value)
	}

	const LinkElement = (!location.pathname || location.pathname === '/') ? ScrollLink : Link

	const { menuItems: menu } = siteFields.menu

	const _menu = menu.nodes

	return (
		<div
			className={`${headerContainerName} ${isBlog ? 'blog-margin' : ''}`}
			onMouseLeave={() => handleHover(-1)}
		>
			<header className={headerClassName}>
				<div className="container">
					<div className="content">
						<Link
							to={'/'}
							className={'logo'}
						>
							<img className="normal" src={logos.standard.sourceUrl} />
							<img className="inverted" src={logos.inverted.sourceUrl} />
						</Link>
						<ul className="scroll-links">

							{_menu.map((item, index) => {
								const {
									label,
									url
								} = item
								if ( url.includes('#') ) {

									const _url = url.replace('#', '')

									return (
										<li key={index}>
											<LinkElement
												activeClass="active"
												to={`${LinkElement === Link ? '/?scrollTo=' : ''}${_url}`}
												spy={true}
												smooth={true}
												offset={-120}
												duration={500}
											>
												{label}
											</LinkElement>
										</li>
									)
								} else {
									return (
										<li key={index}>
											<Link to={url}>
												{label}
											</Link>
										</li>
									)
								}
							})}
						</ul>
						{ !isActiveLink(location, 'blog') &&
							<Select
								className={'language-selector'}
								options={selectOptions}
								value={selectedLanguage}
								onChange={handleSelectChange}
							/>
						}
					</div>
				</div>
			</header>
			<div className={`header-sub-menu ${hoveredMenuItemIndex !== -1 ? 'visible' : ''}`}>
				<ul>
					{selectedChildItems.map((item, index) => {
						const {
							label,
							url
						} = item

						return (
							<li key={index}>
								<Link className={isActiveLink(location, url) ? 'active' : ''} to={url}>
									{label}
								</Link>
							</li>
						)
					})}
				</ul>
			</div>
		</div>
	)
}
