import React from 'react'
import Select from 'react-select'

export default ({
    options,
    inputKey,
    handleChange,
    value,
    label
}) => {
    const optionsArray = options.split('\n')

    const _options = []

    optionsArray.forEach(option => {
        const valueArray = option.split(' : ')

        _options.push({
            value: valueArray[1],
            label: valueArray[0]
        })
    })

    return (
        <Select 
            className={'select'}
            options={_options}
            onChange={handleChange}
            value={value || null}
            placeholder={'Please select'}
        />
    )
}