import gql from 'graphql-tag'
// import { graphql } from "gatsby"

export const SUBMIT_FORM = gql`
    mutation SUBMIT_FORM (
        $data: String
    ) {
        submitForm (
            input: {
                data: $data
                clientMutationId: "uniqueId"
            }
        ) {
            success
        }
    }
`

export const SUBMIT_CONTACT_FORM = gql`
    mutation SUBMIT_CONTACT_FORM (
        $data: String
        $type: String
    ) {
        submitContactForm (
            input: {
                data: $data
                type: $type
                clientMutationId: "uniqueId"
            }
        ) {
            success
        }
    }
`

export const GET_SUBMISSIONS = gql`
    query GET_SUBMISSIONS {
        form_Submissions (
            first: 1000
        ) {
            nodes {
                id
                title
                formSubmissionFields {
                    suburb
                }
            }
        }
    }
`

export const FormFieldsFragment = graphql`
  fragment FormFieldsFragment on WPGraphQL_Form {
    formFields {
        sections {
            title
            rowsHeading
            copy
            rows {
                heading
                columns {
                    label
                    options
                    type    
                }
            }
        }
        buttonLabel
        checkboxConfirmCopy
    }
}
`

export const GET_POSTS_BY_CAT = gql`
    query GET_POSTS_BY_CAT ( 
        $category: String
    ) {
        posts(
            first: 1000,
            after: null,
            where: {
                taxQuery: {
                    relation: AND
                    taxArray: [
                        {
                            operator: IN
                            taxonomy: CATEGORY
                            terms: [$category]
                            field: SLUG
                        }
                    ]
                }
            }
        ) {
            nodes {
                date
                title
                content
                categories {
                    nodes {
                        name
                    }
                }
                featuredImage {
                    caption
                    mediaItemUrl
                }
                link
                postFields {
                    featuredPost
                }
            }
        }
    }
`
