import React from 'react'

export default ({
    inputKey, 
    handleChange,
    value,
    type,
    className
}) => {
    const _type = type === 'password' ? 'password' : 'text'
    
    return (
        <input
            type={_type}
            className={`${type} ${className}`}
            onChange={e => handleChange(e.target.value)}
            value={value || ''}
        />
    )
}