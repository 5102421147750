import React from 'react'

import Header from './header'
import Footer from './footer'
import Modal from './modal'

import PageLoader from './pageLoader'

export default ({ children, location }) => {
	return (
		<div className={`page-container lang-${ location.pathname.replace('/','') } loc-${ JSON.stringify(location) }`}>
			<PageLoader />
			<Header location={location} />
			<main>
				{ children }
			</main>
			<Footer location={location} />
			<Modal />
		</div>
	)
}