import React, {useContext} from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Link from 'components/link'
import { MainContext } from "context/context"
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"

export default ({
    location,
}) => {
    const {
        options,
        currentPage
    } = useContext(MainContext)

    const {
        logos,
        socialLinks,
    } = options

	const LinkElement = (!location.pathname || location.pathname === '/') ? ScrollLink : Link

	const headerLinks = [
		{
			id: 'InteractiveMap',
			label: 'Find A Business'
		}
	]

    return (
        <footer>
            <div className="container">
                <div className="footer-main">
                    <img className="logo" src={logos.inverted.sourceUrl} alt="Love Moreland footer logo"/>
                    <p className="copy" dangerouslySetInnerHTML={{ __html: currentPage.footerAcknowledgement || '' }} />
                </div>
                <div className="social-container">
                    <ul className="scroll-links">
                        <li>
                            <Link to={'https://made.withalpaca.com/locale/moreland-city-council-stay-home-guide/default/itineraries-trails'}>Moreland Trails</Link>
                        </li>
                        <li>
                            <Link to={'https://www.moreland.vic.gov.au/'}>Visit Moreland</Link>
                        </li>
                        {headerLinks.map(({
                            id,
                            label
                        }) => (
                                <li key={id}>
                                    <LinkElement
                                        activeClass="active"
                                        to={`${LinkElement === Link ? '/?scrollTo=' : ''}${id}`}
                                        spy={true}
                                        smooth={true}
                                        offset={-120}
                                        duration={500}
                                    >
                                        {label}
                                    </LinkElement>
                                </li>
                            ))}
                        <li>
                            <a href={`mailto:business@moreland.vic.gov.au`} target="_blank">
                                Add my Business
                            </a>
                        </li>
                        <li>
                            <Link to={'/blog'}>Blog</Link>
                        </li>
                    </ul>
                    <div className="social">
                        <p>
                            Get in touch
                        </p>
                        <ul className="social-links no-rtl">
                            {socialLinks.map((link, index) => {
                                return (
                                    <li key={index}>
                                        <a href={link.url}>
                                            <img src={require(`../images/${link.type}.png`)} alt="Social link for Love Moreland, Buy Local"/>
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
