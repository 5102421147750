import React, { useRef } from 'react'
import Button from 'components/button'

import Slider from 'components/slider'

export default ({
    business
}) => {
    const {
        title,
        content,
        businessFields: {
            logo,
            website,
            socialLinks,
            images
        }
    } = business

    const mainSliderRef = useRef(null)

    return (
        <>
            <div className="main-slider">
                <img className="logo" src={ logo.mediaItemUrl } alt={`Logo for ${ title }`}/>
                { typeof window != 'undefined' &&
                    <Slider
                        className={'carousel'}
                        options={{
                            wrapAround: true,
                            groupCells: 1,
                            prevNextButtons: false,
                            autoPlay: 3000
                        }}
                        flickityRef={ref => mainSliderRef.current = ref}
                    >
                        { images.map((image, index) => {
                            if(!image.image){
                                return null
                            }
                            
                            const backgroundUrl = image.image.mediaItemUrl 

                            return (
                                <div
                                    className="slide"  
                                    key={index}
                                    style={{
                                        backgroundImage: `url(${ backgroundUrl })`
                                    }}
                                />

                            )
                        })}
                    </Slider>
                }
            </div>
            <div className="content">
                <div className="heading-row">
                    <h3>
                        { title }
                    </h3>
                </div>
                { socialLinks &&
                    <div className="social-row">
                        <span>
                            Follow { title }
                        </span>
                        <ul className="social-links">
                            { socialLinks.map((link, index) => {
                                return (
                                    <li key={index}>
                                        <a href={link.url}>
                                            <img src={require(`../../images/purple-${link.type}.png`)} alt={`Social link for ${ title }`}/>
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                }
                <div 
                    className="copy"
                    dangerouslySetInnerHTML={{__html: content}}
                />
                { website ?
                    <div className={'button-row'}>
                        <Button 
                            url={website}
                            label="View website"
                        />
                    </div>
                : null }
            </div>
        </>
    )
}