import React, { useContext, useState, useEffect } from 'react'
import { SUBMIT_CONTACT_FORM } from '../../graphql/queries'
import { useMutation } from '@apollo/client'
import countryCodes from 'utils/countryCodes'
import { cloneDeep } from 'lodash'

import Form from 'components/form'

export default ({
    close,
    form
}) => { 

    const [submitContactForm] = useMutation(SUBMIT_CONTACT_FORM)
    const [isLoading, setIsLoading] = useState(false)
    const [forceLoading, setForceLoading] = useState(false)
    const [fieldValues, setFieldValues] = useState({})
    const [checkboxChecked, setCheckboxChecked] = useState(false)
    const [sentMsg, setSentMsg] = useState('')

    const handleChange = (inputKey, value) => {
        const _fieldValues = cloneDeep(fieldValues)

        const phoneKey = 'Phone Number'

        if(inputKey === 'Country' && !fieldValues[phoneKey]){
            const country = countryCodes.find(country => country.name === value.value)

            _fieldValues[phoneKey] = country.dial_code
        }
        
        _fieldValues[inputKey] = value

        setFieldValues(_fieldValues)
    }

    const handleSubmit = data => {
        const json = JSON.stringify(data)
        setIsLoading(true)

        submitContactForm({
            variables: {
                data: json,
                type: 'Request to be featured'
            },
        }).then(() => {
            setIsLoading(false)
            setFieldValues({})

            setSentMsg('Sent!')

            setTimeout(() => {
                setSentMsg('')
            },3000)

        }).catch(error => {
            setIsLoading(false)
        })
    }

    return (
        <div className='contact-form'>
            <button 
                className="close"
                onClick={close}
            >
                <i className="fe fe-x" />
            </button>
            <Form
                form={form}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
                handleChange={handleChange}
                fieldValues={fieldValues}
                setCheckboxChecked={setCheckboxChecked}
                checkboxChecked={checkboxChecked}
            />
            { sentMsg && <div className="contact-form-sent"><h4 dangerouslySetInnerHTML={{ __html: sentMsg }} /></div> }
        </div>
    )
}