import React, { useState } from 'react'

import TextInput from "components/textInput"
import TextArea from "components/textArea"
import Select from 'components/formFields/selectBox'

export default ({
    column, 
    values, 
    handleChange,
    error,
    type
}) => {
    let Component = null 
    let className = ''

    switch(column.type) {
        case 'text':
        case 'email':
        case 'password':
            Component = TextInput
            break;
        case 'select':
            className = 'select'
            Component = Select
            break;
        case 'textarea':
            Component = TextArea
            break
        }

    const inputKey = column.label

    const [valueState, setValueState] = useState('')

    const assessValue = (value) => {
        handleChange(inputKey, value)
        setValueState(value)
    }

    return (
        <div className={error ? 'has-error' : ''}>
            {type !== 'contact' ?
            <>
                <label>
                    {column.label}
                </label>
                <Component 
                    label={column.label} 
                    options={column.options} 
                    inputKey={inputKey}
                    handleChange={value => handleChange(inputKey, value)}
                    className={className}
                    value={values[inputKey] || undefined}
                    type={column.type}
                />
            </>
            :
            <>
                <Component 
                    label={column.label} 
                    options={column.options} 
                    inputKey={inputKey}
                    handleChange={assessValue}
                    className={`${className} ${valueState.length > 0 ? 'input-has-value' : ''}`}
                    value={values[inputKey] || undefined}
                    type={column.type}
                />
                <label>
                    {column.label}
                </label>
            </>
            }

        </div>
    )
}